import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import SupportBarOne from "../components/SupportBarOne";
import NavbarThree from "../components/NavbarThree";
import BannerFive from "../components/BannerFive";
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const BannerTwo = React.lazy(() => import("../components/BannerTwo"));
const BlogTwo = React.lazy(() => import("../components/BlogTwo"));
const CounterTwo = React.lazy(() => import("../components/CounterTwo"));
const FaqOne = React.lazy(() => import("../components/FaqOne"));
const SolutionTwo = React.lazy(() => import("../components/SolutionTwo"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerTwo = React.lazy(() => import("../components/PartnerTwo"));
const PricingOne = React.lazy(() => import("../components/PricingOne"));
const RequestQuoteOne = React.lazy(() =>
  import("../components/RequestQuoteOne")
);
const ServiceTwo = React.lazy(() => import("../components/ServiceTwo"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const WhyChooseUsTwo = React.lazy(() => import("../components/WhyChooseUsTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const HomeTwo = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          <SupportBarOne/>
          <NavbarThree />

          {/* Banner Two */}
          <BannerFive/>

          <div className='faq-area pd-top-100'> 
          <PartnerTwo />
          </div>
           {/* Service Two */}
           <SolutionTwo/>
             {/* Counter Two */}
         

          {/* About Two */}
           

          

          

          {/* Why Choose Us Two */}
          <WhyChooseUsTwo />

          {/* Request Quote One */}
          <RequestQuoteOne />

          {/* Pricing One */}
          <AboutTwo />
          
          <CounterTwo />

          {/* Video Area One */}
          <div className='faq-area pd-top-120'> 
          <VideoAreaOne />
          </div>

          {/* Faq One */}
          <div className='faq-area pd-top-120'>
            <FaqOne />
          </div>

         

          {/* Footer Two */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeTwo;
