import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import NavbarFour from "../components/NavbarFour";
import NavbarThree from "../components/NavbarThree";
import NavbarTwo from "../components/NavbarTwo";
import SupportBarOne from "../components/SupportBarOne";
import FooterTwo from "../components/FooterTwo";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const ContactInner = React.lazy(() => import("../components/ContactInner"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const Contact = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarThree/>
          <SupportBarOne/>

          {/* Breadcrumb */}
          

          {/* Breadcrumb */}
          <ContactInner />

          {/* Footer One */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default Contact;
