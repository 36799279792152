import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";


const PartnerTwo = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <FaArrowLeft className={className} onClick={onClick} />;
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <FaArrowRight className={className} onClick={onClick} />;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/*partner-area start*/}
      <div
        className='partner-area partner-area-2 pd-top-45 pd-bottom-45'
        style={{ background: "url('/assets/img/partner/bg.png')" }}
      >
        <div className='container'>
          <div className='partner-slider owl-carousel'>
            <Slider {...settings}>
              <div className='item'>
                <div className='thumb'>
                  <img src='https://logos-world.net/wp-content/uploads/2020/08/DHL-Logo-2002-present.jpg' alt='Transpro' className='partner-image'/>
                </div>
              </div>
              <div className='item'>
                <div className='thumb'>
                  <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKCJ6drc_vbp-OwPauaZXmnnduGCD3BVDWmA&s' alt='Transpro' className='partner-image'/>
                </div>
              </div>
              <div className='item'>
                <div className='thumb'>
                  <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/United_Parcel_Service_logo_2014.svg/640px-United_Parcel_Service_logo_2014.svg.png' alt='Transpro' className='partner-image'/>
                </div>
              </div>
              <div className='item'>
                <div className='thumb'>
                  <img src='https://www.airlinepros.com/wp-content/uploads/2023/03/nepal-airlines-1.jpg' alt='Transpro' className='partner-image'/>
                </div>
              </div>
              <div className='item'>
                <div className='thumb'>
                  <img src='https://upload.wikimedia.org/wikipedia/en/thumb/2/22/Hapag_lloyd_logo.svg/2560px-Hapag_lloyd_logo.svg.png' alt='Transpro' className='partner-image'/>
                </div>
              </div>
              <div className='item'>
                <div className='thumb'>
                  <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/FedEx_Express.svg/640px-FedEx_Express.svg.png' alt='Transpro' className='partner-image'/>
                </div>
              </div>
               
            </Slider>
          </div>
        </div>
      </div>
      {/*partner-area end*/}
    </>
  );
};

export default PartnerTwo;
