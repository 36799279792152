import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const BannerFive = () => {
  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  });

  var { slider1, slider2 } = useRef();

  useEffect(() => {
    setState({
      nav1: slider1,
      nav2: slider2,
    });
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  const backgroundStyle = {
    position: 'relative',
    backgroundImage: "url('https://img-c.udemycdn.com/course/750x422/3554469_8b80_9.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh', // adjust height as needed
  };
  
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    zIndex: 1,
  };
  
  const contentStyle = {
    position: 'relative',
    zIndex: 2,
    color: 'white',
    textAlign: 'center',
    paddingTop: '20%',
  };
  
  return (
    <>
      {/* header start */}
      <div className='home-area home-v3 '>
        <div className='header-slider header-slider2'>
          <Slider
            {...settings}
            asNavFor={state.nav2}
            ref={(slider) => (slider1 = slider)}
          >
            <div>
              <div
                className='header-bg'
                style={{
                  backgroundImage: "url('https://images.unsplash.com/photo-1590497008432-598f04441de8?q=80&w=1991&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
                }}
              >
                <div className='container'>
                  <div className='row header-height justify-content-start'>
                    <div className='col-lg-6'>
                      <div className='header-inner-wrap'>
                        <div className='header-inner'>
                          {/* header inner */}
                          <h1 className='title animated slideInRight'>
                            Efficient & Relaible Cargo Service
                          </h1>
                          <p className="text-white">
                          Our goal is to fully understand the needs of our clients. We aim to operate as an extension of your operation in delivering highly competitive, tailor-made logistics solutions
                          </p>
                          <div className='btn-wrapper'>
                            <Link to="/about" className='boxed-btn'>
                              <span>Know More </span>
                            </Link>
                          </div>
                        </div>
                        {/* //.header inner */}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='right-wrapper'>
                        <div className='inner'>
                          <h2>
                            Pashupati Cargo & Export Services
                          </h2>
                          <div className='list'>
                            <ul>
                              <li>
                                Air , Ocean , Land & Rail Transport
                              </li>
                              <li>Cusotm Clearance</li>
                              <li>Warehousing</li>
                              <li>FCL /LCL Import & Export</li>
                              <li>Door to Door Delivery</li>
                              <li>Express Cargo</li>
                              <li>And all other cargo needs</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                className='header-bg'
                style={{
                  backgroundImage: "url('https://images.unsplash.com/photo-1590497008432-598f04441de8?q=80&w=1991&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
                }}
              >
                <div className='container'>
                  <div className='row header-height justify-content-start'>
                    <div className='col-lg-6'>
                      <div className='header-inner-wrap'>
                        <div className='header-inner'>
                          {/* header inner */}
                          <h1 className='title animated slideInRight'>
                            Efficient & Relaible Cargo Service
                          </h1>
                          <p className="text-white">
                          Our goal is to fully understand the needs of our clients. We aim to operate as an extension of your operation in delivering highly competitive, tailor-made logistics solutions
                          </p>
                          <div className='btn-wrapper'>
                          <Link to="/about" className='boxed-btn'>
                              <span>Know More </span>
                            </Link>
                          </div>
                        </div>
                        {/* //.header inner */}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='right-wrapper'>
                        <div className='inner'>
                          <h2>
                            Pashupati Cargo & Export Services
                          </h2>
                          <div className='list'>
                            <ul>
                              <li>
                                Air , Ocean , Land & Rail Transport
                              </li>
                              <li>Cusotm Clearance</li>
                              <li>Warehousing</li>
                              <li>FCL /LCL Import & Export</li>
                              <li>Door to Door Delivery</li>
                              <li>Express Cargo</li>
                              <li>And all other cargo needs</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                className='header-bg'
                style={{
                  backgroundImage: "url(assets/img/slide-v3/bg-03.png)",
                }}
              >
                <div className='container'>
                  <div className='row header-height justify-content-start'>
                    <div className='col-lg-6'>
                      <div className='header-inner-wrap'>
                        <div className='header-inner'>
                          {/* header inner */}
                          <h1 className='title animated slideInRight'>
                            Cargo consists of goods conveyed{" "}
                          </h1>
                          <p className='sub-title'>
                            Led ask possible mistress relation elegance eat
                            likewise debating. By message or am nothing amongst
                            chiefly address. The its enable direct men depend
                            highly. Ham windows sixteen who inquiry fortune
                            demands. Is be upon sang fond must shew.
                          </p>
                          <div className='btn-wrapper'>
                          <Link to="/about" className='boxed-btn'>
                              <span>Know More </span>
                            </Link>
                          </div>
                        </div>
                        {/* //.header inner */}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='right-wrapper'>
                        <div className='inner'>
                          <h2>
                            Land freight transport company for all types of
                            goods
                          </h2>
                          <div className='list'>
                            <ul>
                              <li>
                                Online transportation and forwarding software
                              </li>
                              <li>Transport orders</li>
                              <li>Fleet management</li>
                              <li>Goods or produce being transported</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                className='header-bg'
                style={{
                  backgroundImage: "url(assets/img/slide-v3/bg-04.png)",
                }}
              >
                <div className='container'>
                  <div className='row header-height justify-content-start'>
                    <div className='col-lg-6'>
                      <div className='header-inner-wrap'>
                        <div className='header-inner'>
                          {/* header inner */}
                          <h1 className='title animated slideInRight'>
                            Freight transport companies
                          </h1>
                          <p className='sub-title'>
                            Led ask possible mistress relation elegance eat
                            likewise debating. By message or am nothing amongst
                            chiefly address. The its enable direct men depend
                            highly. Ham windows sixteen who inquiry fortune
                            demands. Is be upon sang fond must shew.
                          </p>
                          <div className='btn-wrapper'>
                          <Link to="/about" className='boxed-btn'>
                              <span>Know More </span>
                            </Link>
                          </div>
                        </div>
                        {/* //.header inner */}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='right-wrapper'>
                        <div className='inner'>
                          <h2>
                            Land freight transport company for all types of
                            goods
                          </h2>
                          <div className='list'>
                            <ul>
                              <li>
                                Online transportation and forwarding software
                              </li>
                              <li>Transport orders</li>
                              <li>Fleet management</li>
                              <li>Goods or produce being transported</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* header end */}
    </>
  );
};

export default BannerFive;
