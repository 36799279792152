import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import NavbarThree from "../components/NavbarThree";
import SupportBarOne from "../components/SupportBarOne";
import FooterTwo from "../components/FooterTwo";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const ContactInner = React.lazy(() => import("../components/ContactInner"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
 
const Esewa = () => {
  return (
    <Suspense fallback={<Preloader />}>
      {/* Search Popup */}
      <SearchPopup />

      {/* Navbar One */}
      <NavbarThree />
      <SupportBarOne />

      <div className="row mt-5">
        <div className="col-lg-12">
          <center> <img src="assets/img/esewa.jpeg" className="img-fluid" alt="Esewa"  width={400}/></center>
        </div>
         
      </div>

      {/* Footer One */}
      <FooterTwo/>

      {/* Footer Bottom One */}
      <FooterBottomOne />
    </Suspense>
  );
};

export default Esewa;
