import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import NavbarThree from "../components/NavbarThree";
import SupportBarOne from "../components/SupportBarOne";
import FooterTwo from "../components/FooterTwo";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const ContactInner = React.lazy(() => import("../components/ContactInner"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const MBL = () => {
  return (
    <Suspense fallback={<Preloader />}>
      {/* Search Popup */}
      <SearchPopup />

      {/* Navbar Three */}
      <NavbarThree />
      <SupportBarOne />

      <div className="row mt-5 bg-light p-5">
        <div className="col-lg-6">
          <center>
            <img src="assets/img/bank.jpeg" className="img-fluid" alt="Esewa" width={400} />
          </center>
        </div>
        <div className="col-lg-6">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th colSpan={2}>Bank Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Bank Name</th>
                <td>Nepal Bank Limited</td>
              </tr>
              <tr>
                <th scope="row">Branch</th>
                <td>Thamel Branch</td>
              </tr>
              <tr>
                <th scope="row">Account No</th>
                <td>00200100498648000001</td>
              </tr>
              <tr>
                <th scope="row">Account Name</th>
                <td>Pashupati Cargo and Export Services Pvt Ltd</td>
              </tr>
              <tr>
                <th scope="row">Swift Code</th>
                <td>NEBLNPKA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Footer Two */}
      <FooterTwo />

      {/* Footer Bottom One */}
      <FooterBottomOne />
    </Suspense>
  );
};

export default MBL;
